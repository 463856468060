import ImageBlobReduce from 'image-blob-reduce';

// utils
import fireSwal from '~/scripts/fire-swal';
import { translate } from '~/scripts/locales/translations';

const MAX_IMAGE_SIZE = 10485760; // 10 MB
const MAX_IMAGE_DIMENSION = 1200; // px

const reduce = new ImageBlobReduce();
const locale = document.querySelector('html').getAttribute('lang');

export default function fileUpload({ originalUrl = '', originalFilename = '', isPreviewActive }) {
  return {
    originalUrl,
    originalFilename,
    isPreviewActive,
    destroyFile: '',

    isImageAttribute() {
      if (this.$refs.imagePreview) {
        return true;
      } else {
        return false;
      }
    },

    isFileAttribute() {
      if (this.$refs.filenamePreview) {
        return true;
      } else {
        return false;
      }
    },

    handleInput() {
      const input = this.$refs.input;
      const file = input.files[0];

      if (this.isFileAttribute()) {
        this.$refs.filenamePreview.innerText = file.name;
        this.$refs.filenamePreview.removeAttribute('href');
        this.isPreviewActive = true;
      } else if (this.isImageAttribute()) {
        const preview = this.$refs.imagePreview;
        const filenameEl = this.$refs.imageFilename;

        // check if the file is an image, if not return
        if (file.type.includes('image')) {
          fireSwal.loading({ title: translate('img-resize', 'analyzing', locale) });
          if (file.size > MAX_IMAGE_SIZE) {
            fireSwal.error({
              title: translate('img-resize', 'size-too-large__title', locale),
              text: translate('img-resize', 'size-too-large__text', locale),
            });
            input.value = null;
            return;
          }
        } else {
          return;
        }

        const reader = new FileReader();
        const img = new Image();
        reader.readAsDataURL(file);

        // we wait for the reader to load the file
        reader.onload = () => {
          img.src = reader.result;
          // we wait for the image to read the reader
          img.onload = () => {
            // validate dimension
            if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
              fireSwal.loading({ title: translate('img-resize', 'loading', locale) });

              reduce
                .toBlob(file, { max: MAX_IMAGE_DIMENSION })
                .then((blob) => {
                  // transfer reduced image back to input
                  let transfer = new DataTransfer();
                  const reducedImg = new File([blob], file.name);
                  transfer.items.add(reducedImg);
                  input.files = transfer.files;

                  fireSwal.success({ title: translate('img-resize', 'success', locale) });
                })
                .catch(() =>
                  fireSwal.error({
                    title: translate('img-resize', 'error__title', locale),
                    text: translate('img-resize', 'error__text', locale),
                  })
                );
            } else {
              fireSwal.success({ title: translate('img-resize', 'size-ok', locale) });
            }
          };
          // if there is a preview image (e.g. carousel)
          if (preview) preview.src = reader.result;
          if (filenameEl) filenameEl.innerText = file.name;
          this.isPreviewActive = true;
        };
      } else {
        console.log('Something went wrong, please check the fileUpload component');
      }
    },

    // delete new file:
    // - if there was an original file present and there is a new file selected, reset the preview to the old file and clear the input
    // - if not, set the destroyFile flag and clear the preview
    deleteFile() {
      if (this.originalFilename != '' && this.$refs.input.files[0]) {
        this.$refs.input.value = '';
        this.resetPreview();
      } else {
        this.destroyFile = true;
        this.clearPreview();
      }
    },

    resetPreview() {
      if (this.isImageAttribute()) {
        this.$refs.imagePreview.src = this.originalUrl;
        this.$refs.imageFilename.innerText = this.originalFilename;
      }
      if (this.isFileAttribute()) {
        this.$refs.filenamePreview.href = this.originalUrl;
        this.$refs.filenamePreview.innerText = this.originalFilename;
      }
      this.isPreviewActive = true;
    },

    clearPreview() {
      if (this.isImageAttribute()) {
        this.$refs.imagePreview.removeAttribute('src');
        this.$refs.imageFilename.innerText = '';
      }
      if (this.isFileAttribute()) this.$refs.filenamePreview.removeAttribute('href');
      this.isPreviewActive = false;
    },

    handleDrop(event) {
      // Ignore if dataTransfer doesn't contain exactly one item
      if (event.dataTransfer.files.length !== 1) return;
      this.$refs.input.files = event.dataTransfer.files;
      this.handleInput();
    },

    dropzone: {
      ['@drop.prevent'](event) {
        this.handleDrop(event);
      },
      ['@dragover.prevent']() {
        // Just here to prevent the file from being opened
      },
    },
  };
}
